<template>
  <a-form
      ref="formModal"
      :model="formState"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
      @finish="onFinish"
  >
    <a-row :gutter="30">
      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Tiêu đề" name="title">
          <cds-input :read-only="disabled" class="w-100 ant-input-sm" v-model:value="formState.title" :maxlength="200"
                     show-count
                     size="small"
                     placeholder="Tiêu đề"/>
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Tóm tắt" name="intro">
          <cds-input :read-only="disabled" class="w-100 ant-input-sm" v-model:value="formState.intro" :maxlength="50"
                     show-count
                     size="small"
                     placeholder="Tóm tắt"/>
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Danh mục" name="categoryId">
          <cds-tree-select
              :read-only="disabled"
              v-model:value="formState.categoryId"
              :disabled="disabled"
              :tree-data="categories"
              :field-names="{ label: 'title', value: 'id' }"
          />
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Ngày kích hoạt" name="publishTime">
          <cds-date-picker
              :show-time="{ format: 'HH:mm:ss' }"
              :format="'DD/MM/YYYY HH:mm:ss'"
              v-model:value="formState.publishTime"
              :disabled-date="disabledToDate"
              :read-only="disabled"
              placeholder="Ngày kích hoạt">
          </cds-date-picker>
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item
            class="custom-form"
            label="Ảnh thu nhỏ"
            name="thumbPath"
        >
          <cds-upload
              :disabled="disabled"
              accept=".jpg,.png,.jpeg"
              v-model:value="formState.thumbPath"
              root-path="video"
          />
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item name="hot">
          <a-checkbox v-model:checked="formState.hot" :disabled="disabled">HOT</a-checkbox>
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Nguồn" name="source">
          <cds-input :read-only="disabled" class="w-100 ant-input-sm"
                     v-model:value="formState.source"
                     :maxlength="1000"
                     show-count
                     size="small"
                     placeholder="Nguồn"/>
        </a-form-item>
      </a-col>

    </a-row>

    <a-row :gutter="30">
      <a-col :xxl="24" :xl="24" :lg="24" :md="24" :xs="24" class="content">
        <a-form-item label="Nội dung" name="content" :label-col="labelColDes" :wrapper-col="wrapperColDes">
          <editor v-model:value="formState.content"
                  :disabled="disabled"/>
        </a-form-item>
      </a-col>
    </a-row>
    <hr class="footer">

    <div class="w-100 text-right">
      <cds-button key="back" type="light" size="small" @click="onCancel">
        <cds-feather-icons class="mr-10" type="x"/>
        {{ disabled ? 'Đóng' : 'Hủy bỏ' }}
      </cds-button>
      <cds-button
          class="ml-20"
          v-if="!disabled"
          key="submit"
          type="primary"
          size="small"
          type-html="submit"
      >
        <cds-feather-icons type="save"/>
        Lưu
      </cds-button>
    </div>

  </a-form>
</template>

<script>
import {computed, defineComponent, reactive, ref, toRaw} from 'vue'
import {useStore} from 'vuex'
import _ from 'lodash'
import {FORM_MODE} from '@/util/common-constant'
import {maxlength, required} from '@/util/validation'
import {lessThanCurrent} from '@/util/common-utils'
import CdsUpload from '@/components/upload';
import Editor from '@/components/editor/Editor';

export default defineComponent({
  components: {
    'cds-upload': CdsUpload,
    'editor': Editor,
  },
  emits: ['saveSuccess'],
  setup(props, {emit}) {
    const {state, dispatch} = useStore()
    const formState = reactive(state.articles.articles)
    const formMode = computed(() => state.articles.formMode)
    const categories = computed(() => state.category.listAll || []);
    const disabled = formMode.value === FORM_MODE.VIEW
    const rules = computed(() => {
      if (disabled) return {}
      return {
        title: [required('Tiêu đề'), maxlength(200, 'Tiêu đề')],
        intro: [maxlength(200, 'Tóm tắt')],
        content: [required('Nội dung')],
        categoryId: [required('Danh mục')],
        thumbPath: [required('Ảnh')]
      }
    })
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    }
    const labelColDes = {
      lg: 4,
      md: 4,
      xs: 24,
    }
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    }
    const wrapperColDes = {
      lg: 24,
      md: 24,
      xs: 24,
    }
    const formModal = ref(null)
    const onCancel = () => dispatch('articles/setVisible', false)
    const onFinish = async () => {
      try {
        let data = _.cloneDeep(toRaw(formState))
        data = {
          ...data,
          thumbPath: data.thumbPath && data.thumbPath[0],
          hot: data.hot ? 1 : 0
        }
        if (await dispatch(formMode.value === FORM_MODE.CREATE ? 'articles/create' : 'articles/update', data)) {
          emit('saveSuccess')
        }
        // eslint-disable-next-line no-empty
      } catch {

      }
    }
    return {
      formState,
      rules,
      labelCol,
      labelColDes,
      wrapperColDes,
      wrapperCol,
      formModal,
      onFinish,
      onCancel,
      disabled,
      categories,
      disabledToDate: lessThanCurrent
    }
  },
})
</script>

<style scoped lang="scss">
  .content {
    :deep(.ql-editor) {
      min-height: 200px;
      max-height: 400px;
      overflow: auto;
    }
  }
</style>
