<template>
  <cds-main class="">
    <cds-cards title="Tìm kiếm">
      <a-form
          ref="formRef"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
          :rules="rules"
          :validate-trigger="['change', 'blur']"
      >
        <a-row :gutter="30">
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Tiêu đề" name="name">
              <cds-input class="ant-input-sm" v-model:value="formState.name" :maxlength="50" show-count
                         size="small"
                         placeholder="Tiêu đề"
                         @press-enter="onSearch"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <cds-search-form-button
          :buttons="buttonsForm"
          @onClear="resetForm"
          @onSearch="onSearch"
          @onCreate="preCreate"
          @onDelete="onDelete"
      />
    </cds-cards>
    <cds-cards title="Danh sách Chuyên mục">
      <cds-table
          ref="table"
          index-column
          has-checkbox
          key-field="id"
          pagination
          :columns="columns"
          :api-fetch="apiFetch"
          :param-fetch="paramFetch"
      >
        <template #custom-body="{column, record}">
          <template v-if="column.key === 'isActive' && record">
            <tag tag-type="colorful" :color="record.isActive === 1 ? '#108ee9' : 'rgb(209 0 0)'" :text="status(record.isActive)"></tag>
          </template>
          <template v-if="column.key === 'action' && record">
            <action-button
                :buttons="buttonActions"
                @onEdit="onEdit(record)"
                @onView="onView(record)"
            />
          </template>
        </template>
      </cds-table>
    </cds-cards>
  </cds-main>

  <cds-modal
      :title="titleModal"
      :visible="visible"
      :loading="loading"
      :width="'50vw'"
      :mask-closable="false"
      hide-footer
      @onCancel="visible = false"
  >
    <form-modal ref="modal" @saveSuccess="saveSuccess"></form-modal>
  </cds-modal>
</template>

<script src="./category-controller.js"></script>

<style scoped>

</style>
