import { computed, onMounted, reactive, ref, toRaw } from 'vue';
import ConstantAPI from '@/config/ConstantAPI';
import ActionButton from '@/components/action-button';
import { ModalStyled } from '@/components/modals/styled';
import { useForm } from 'ant-design-vue/es/form';
import { useStore } from 'vuex';
import FormModal from './FormModal';
import { formatDate, getText, greaterThanCurrent } from '@/util/common-utils';
import { maxlength } from '@/util/validation';
import _ from 'lodash';
import { mapFields } from 'vuex-map-fields';
import Tag from '../../../../components/tags/Tag';
import { defaultButton, FORM_MODE, STATUS } from '@/util/common-constant';
import UserDetail from './UserDetail';
import Table from '../../../../components/table/Table';

const apiFetch = ConstantAPI.user.SEARCH;
export default {
  name: 'Role',
  components: {
    'cds-table': Table,
    ActionButton,
    ModalStyled,
    FormModal,
    Tag,
    UserDetail,
  },
  setup() {
    const columns = ref([
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        resizable: true,
        width: 160,
      },
      {
        title: 'Họ và tên',
        dataIndex: 'fullName',
        key: 'fullName',
        resizable: true,
        width: 160,
      },
      {
        title: 'Giới tính',
        dataIndex: 'gender',
        resizable: true,
        key: 'gender',
        width: 80,
        align: 'center',
      },
      {
        title: 'Nhóm người dùng',
        dataIndex: 'cpId',
        resizable: true,
        key: 'cpId',
        width: 140,
        customRender({ record }) {
          const data = cps.value.find(e => e.value === record.cpId);
          return (data && data.label) || '';
        },
      },
      {
        title: 'Điện thoại',
        dataIndex: 'phone',
        resizable: true,
        key: 'phone',
        width: 120,
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        resizable: true,
        key: 'status',
        width: 80,
        align: 'center',
      },
      {
        title: 'Ngày tạo',
        key: 'createdTime',
        sortDirections: ['descend', 'ascend'],
        resizable: true,
        sortable: true,
        dataIndex: 'createdTime',
        customRender({ record }) {
          return formatDate(record.createdTime);
        },
        align: 'center',
        width: 150,
      },
      {
        title: 'Ngày sửa',
        key: 'modifyTime',
        resizable: true,
        sortable: true,
        dataIndex: 'modifyTime',
        customRender({ record }) {
          if (!record.modifyTime) return '';
          return formatDate(record.modifyTime);
        },
        align: 'center',
        width: 150,
      },
      {
        key: 'action',
        width: 110,
      },
    ]);
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    };
    const { buttonsForm, buttonActions } = defaultButton('user');
    const formState = reactive({
      username: '',
      cpId: null,
      dateFrom: null,
      dateTo: null,
    });
    const rules = ref({
      username: [maxlength(50, 'Username')],
    });
    const { resetFields, validate, validateInfos } = useForm(formState, rules);
    const setParamFetch = () => {
      paramFetch.username = formState.username;
      paramFetch.cpId = formState.cpId;
      paramFetch.dateFrom = formState.dateFrom;
      paramFetch.dateTo = formState.dateTo;
    };
    const resetForm = () => {
      resetFields();
      formState.dateFrom = null;
      formState.dateTo = null;
      formState.cpId = null;
      setParamFetch();
      table.value.resetAndFetch();
    };
    let paramFetch = _.cloneDeep(toRaw(formState));
    const fieldNames = { label: 'name', key: 'value' };
    const table = ref(null);
    const { state, dispatch } = useStore();
    const isView = computed(() => state.user.formMode === FORM_MODE.VIEW);
    const cps = computed(() => state.cp.listAll || []);
    const loading = computed(() => state.user.loading);
    const onSearch = () => {
      validate().then(
        () => {
          setParamFetch();
          table.value.fetchData().then();
        },
        () => {}
      );
    };
    onMounted(() => {
      dispatch('cp/getAll').then();
      dispatch('role/getAll').then();
    });
    const disabledDate = greaterThanCurrent();
    const status = computed(() => (value) => getText(value, STATUS));
    const gender = computed(() => (value) => {
      if (value === 1) return { text: 'Nam', color: '#006899' };
      if (value === 0) return { text: 'Nữ', color: '#e7699e' };
      return { text: 'Khác', color: '#7c7c7c' };
    });
    return {
      columns,
      apiFetch,
      labelCol,
      wrapperCol,
      formState,
      resetFields,
      resetForm,
      onSearch,
      validate,
      validateInfos,
      rules,
      disabledDate,
      dispatch,
      table,
      titleModal: '',
      paramFetch,
      cps,
      fieldNames,
      status,
      gender,
      loading,
      buttonsForm,
      buttonActions,
      isView,
    };
  },
  computed: {
    ...mapFields('user', ['visible']),
  },
  methods: {
    preCreate() {
      this.dispatch('user/preCreate').then(
        () => (this.titleModal = 'Thêm mới Người dùng')
      );
    },
    saveSuccess() {
      this.dispatch('user/setVisible', false).then(() =>
        this.$refs.table.resetAndFetch(true)
      );
    },
    onEdit(row) {
      this.dispatch('user/preUpdate', row).then();
      this.titleModal = 'Cập nhật Người dùng';
    },
    onView(row) {
      this.dispatch('user/preView', row).then();
      this.titleModal = 'Xem chi tiết Người dùng';
    },
    async onDelete() {
      if (await this.dispatch('user/delete', this.$refs.table.selectedRows))
        this.$refs.table.resetAndFetch(true);
    },
  },
};
