<template>
  <cds-main class="">
    <cds-cards title="Tìm kiếm">
      <a-form
          ref="formRef"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
          :rules="rules"
          :validate-trigger="['change', 'blur']"
      >
        <a-row :gutter="30">
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Username" name="username">
              <cds-input class="w-100 ant-input-sm" v-model:value="formState.username" :maxlength="50" show-count
                         size="small"
                         placeholder="Username"
                         @press-enter="onSearch"/>
            </a-form-item>
          </a-col>
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <cds-from-to-date
                :disabled-date="disabledDate"
                v-model:dateFrom="formState.dateFrom"
                v-model:dateTo="formState.dateTo"
            />
          </a-col>
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Nhóm người dùng" name="cpId">
              <cds-select :options="cps"
                          :is-all="true"
                          v-model:value="formState.cpId"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <cds-search-form-button
          :buttons="buttonsForm"
          @onClear="resetForm"
          @onSearch="onSearch"
          @onCreate="preCreate"
          @onDelete="onDelete"
      />
    </cds-cards>
    <cds-cards title="Danh sách Người dùng">
      <cds-table
          ref="table"
          has-checkbox
          index-column
          key-field="userId"
          pagination
          :columns="columns"
          :api-fetch="apiFetch"
          :param-fetch="paramFetch"
      >
        <template #custom-body="{column, record}">
          <template v-if="column.key === 'gender' && record">
            <tag tag-type="colorful" :color="gender(record.gender).color" :text="gender(record.gender).text"></tag>
          </template>
          <template v-if="column.key === 'status' && record">
            <tag tag-type="colorful" :color="record.status === 1 ? '#108ee9' : 'rgb(209 0 0)'" :text="status(record.status)"></tag>
          </template>
          <template v-if="column.key === 'action' && record">
            <action-button
                :buttons="buttonActions"
                @onEdit="onEdit(record)"
                @onView="onView(record)"
            />
          </template>
        </template>
      </cds-table>
    </cds-cards>
  </cds-main>

  <cds-modal
      :title="titleModal"
      :visible="visible"
      :loading="loading"
      hide-footer
      width="700px"
      @onCancel="visible = false"
  >
    <user-detail v-if="isView" />
    <form-modal v-else ref="modal" @saveSuccess="saveSuccess"></form-modal>
  </cds-modal>
</template>

<script src="./user-controller.js"></script>

<style scoped>

</style>
