<template>
  <cds-main class="wrapper">
    <a-form
        ref="formModal"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
        :rules="rules"
        :validate-trigger="['change', 'blur']"
        @finish="onFinish"
    >
      <a-row :gutter="30">
        <a-col :span="12">
          <a-form-item label="Số điện thoại" name="msisdn">
            <cds-input
                class="w-100 ant-input-sm" v-model:value="formState.msisdn" :maxlength="10" show-count
                size="small"
                placeholder="Số điện thoại"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Nhà cung cấp" name="provider">
            <cds-select :options="topUpProviders"
                        placeholder="Nhà cung cấp"
                        v-model:value="formState.provider"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="30" class="my-4">
        <a-col :xxl="8" :xl="8" :lg="8" :md="8" :xs="24" v-for="item in moneyAmount" :key="item.value" @click="choseAmount(item)">
          <cds-cards class="text-center card-content" :class="amountChose === item.value ? 'chose' : '' ">
            <span> {{ item.label }} </span>
          </cds-cards>
        </a-col>
      </a-row>

      <a-row :gutter="30">
        <a-col :span="24" class="text-center">
          <cds-button class="search-btn" type="primary">NẠP TIỀN</cds-button>
        </a-col>
      </a-row>
    </a-form>

  </cds-main>

</template>
<script src="./topup-controller.js"></script>

<style scoped lang="scss">
.wrapper {
  .card-content {
    cursor: pointer;
    height: 70px;
    line-height: 20px;
    font-size: 30px;

    &:hover {
      background: #1784ef;
      color: #fff;
    }
  }

  .chose {
    background: #1784ef !important;
    color: #fff !important;
  }
}
</style>
