<template>
  <cds-main class="">
    <cds-cards title="Tìm kiếm">
      <a-form
          ref="formRef"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
          :rules="rules"
          :validate-trigger="['change', 'blur']"
      >
        <a-row :gutter="30">
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Tiêu đề" name="name">
              <cds-input class="ant-input-sm" v-model:value="formState.name" :maxlength="500" show-count
                         size="small"
                         placeholder="Tiêu đề"
                         @press-enter="onSearch"/>
            </a-form-item>
          </a-col>
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Danh mục" name="categoryId">
              <cds-select :options="categories"
                          placeholder="Danh mục"
                          :is-all="true"
                          v-model:value="formState.categoryId"/>
            </a-form-item>
          </a-col>
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Trạng thái" name="status">
              <cds-select :options="status"
                          placeholder="Trạng thái"
                          :is-all="true"
                          v-model:value="formState.status"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <cds-search-form-button
          :buttons="buttonsForm"
          @onClear="resetForm"
          @onSearch="onSearch"
          @onApproval="onApprovals"
          @onCancelApproval="onCancelApprovals"
      />
    </cds-cards>
    <cds-cards title="Danh sách Bài viết">
      <cds-table
          ref="table"
          index-column
          has-checkbox
          key-field="id"
          pagination
          :columns="columns"
          :api-fetch="apiFetch"
          :param-fetch="paramFetch"
      >
        <template #custom-body="{column, record}">
          <template v-if="column.key === 'thumbPath' && record">
            <img alt="example" style="width: 100%" :src="record.thumbPath"/>
          </template>
          <template v-if="column.key === 'hot' && record">
            <Icon class="icon-single">
              <font-awesome-icon
                  :icon="record.hot === 1 ? 'fa-solid fa-check' : 'fa-solid fa-times'"
                  size="1x"
                  :color="record.hot === 1 ? 'green' : 'red'"
              />
            </Icon>
          </template>
          <template v-if="column.key === 'status' && record">
            <div class="tag">
              <tag tag-type="colorful" :color="record.status === 1 ? '#108ee9' : 'rgb(209 0 0)'"
                   :text="statusText(record.status)"></tag>
            </div>
          </template>
          <template v-if="column.key === 'action' && record">
            <action-button
                :buttons="buttonActions"
                :is-show-approval="record.status === 1"
                @onApproval="onApproval(record)"
                @onCancelApproval="onCancelApproval(record)"
                @onTop="onTop(record)"
            />
          </template>
        </template>
      </cds-table>
    </cds-cards>
  </cds-main>

</template>

<script src="./approval-controller.js"></script>

<style scoped lang="scss">
.tag {
  text-align: center;
}
</style>
