<template>
  <div class="example">
    <div v-if="disabled" class="mt-3" v-html="contentValue" />

    <QuillEditor v-else ref="mainContentEditor"
                 theme="snow"
                 toolbar="full"
                 v-model:content="contentValue"
                 :content-type="'html'"
                 :read-only="disabled"
                 :modules="modules"
                 @editorChange="onEditorChange"

    />
  </div>
</template>

<script>
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import ImageUploader from 'quill-image-uploader';
import _ from 'lodash';
import VueTypes from "vue-types";
import {ref} from "vue";
import {DataService} from "@/dataService/dataService";
import ConstantAPI from "@/config/ConstantAPI";
import {notification} from "ant-design-vue";

export default {
  name: 'Editor',
  title: 'Theme: snow',
  components: {
    QuillEditor
  },
  props: {
    value: VueTypes.string.def(''),
    disabled: VueTypes.bool.def(true),
  },
  setup(props) {
    const contentValue = ref(props.value);
    const enableValue = ref(props.disabled);
    const modules = {
      name: 'imageUploader',
      module: ImageUploader,
      options: {
        upload: file => {
          return new Promise(async (resolve, reject) => {
            const formData = new FormData();
            const config = {
              headers: {'content-type': 'multipart/form-data'},
            };
            formData.append('file', file);
            try {
              const res = await DataService.upload(
                  ConstantAPI.upload_service.UPLOAD,
                  formData,
                  config
              );
              if (res.code === '200') {
                resolve(res.data.url);
              } else {
                notification.error({
                  message: 'Thông báo',
                  description: res.error,
                  duration: 4,
                });
                reject("Lỗi khi upload file");
              }
            } catch (err) {
              reject("Lỗi khi upload file");
            }
          })
        }
      }
    }
    return {
      contentValue,
      enableValue,
      modules
    };
  },
  methods: {
    onEditorChange: _.debounce(function () {
      this.contentValue = this.$refs.mainContentEditor.content
      this.$emit('update:value', this.contentValue)
    }, 500),
  }
}

</script>

<style lang="scss" scoped>
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 40rem;
    overflow: hidden;
  }

  .output {
    width: 100%;
    height: 20rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;

    &.code {
      padding: 1rem;
      height: 16rem;
    }

    &.ql-snow {
      border-top: none;
      height: 24rem;
    }
  }
}
</style>
