<template>
  <a-form
      ref="formModal"
      :model="formState"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
      @finish="onFinish"
  >
    <a-row :gutter="30">
      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Tên đối tác" name="name">
          <cds-input :read-only="disabled" class="w-100 ant-input-sm" v-model:value="formState.name" :maxlength="50"
                     show-count
                     size="small"
                     placeholder="Vai trò"/>
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Điện thoại" name="tel">
          <cds-input :read-only="disabled" class="w-100 ant-input-sm" v-model:value="formState.tel" :maxlength="15"
                     show-count
                     size="small"
                     placeholder="Điện thoại"/>
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Nhóm" name="groupId">
          <cds-select :options="cpGroup"
                      placeholder="Nhóm"
                      :read-only="disabled"
                      v-model:value="formState.groupId"/>
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Email" name="email">
          <cds-input :read-only="disabled" class="w-100 ant-input-sm" v-model:value="formState.email" :maxlength="200"
                     show-count
                     size="small"
                     placeholder="Email"/>
        </a-form-item>
      </a-col>

      <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
        <a-form-item label="Kích hoạt" name="active">
          <cds-select :options="status"
                      placeholder="Kích hoạt"
                      :read-only="disabled"
                      v-model:value="formState.active"/>
        </a-form-item>
      </a-col>

    </a-row>
    <hr class="footer">

    <div class="w-100 text-right">
      <cds-button key="back" type="light" size="small" @click="onCancel">
        <cds-feather-icons class="mr-10" type="x"/>
        {{ disabled ? 'Đóng' : 'Hủy bỏ' }}
      </cds-button>
      <cds-button
          class="ml-20"
          v-if="!disabled"
          key="submit"
          type="primary"
          size="small"
          type-html="submit"
      >
        <cds-feather-icons type="save"/>
        Lưu
      </cds-button>
    </div>

  </a-form>
</template>

<script>
import {computed, defineComponent, reactive, ref, toRaw} from 'vue'
import {useStore} from 'vuex'
import _ from 'lodash'
import {FORM_MODE, STATUS} from '@/util/common-constant'
import {maxlength, required, emailRule, numberRule} from '@/util/validation'

export default defineComponent({
  emits: ['saveSuccess'],
  setup (props, {emit}) {
    const {state, dispatch} = useStore()
    const formState = reactive(state.cp.cp)
    const formMode = computed(() => state.cp.formMode)
    const disabled = formMode.value === FORM_MODE.VIEW
    const rules = computed(() => {
      if (disabled) return {}
      return {
        name: [required('Tên đối tác'), maxlength(50, 'Tên đối tác')],
        groupId: [required('Nhóm')],
        tel: [maxlength(12, 'Số điện thoại'), numberRule('Số điện thoại')],
        email: [emailRule('Email')],
      }
    })
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    }
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    }
    const formModal = ref(null)
    const cpGroup = computed(() => state.cpGroup.list || [])
    const onCancel = () => dispatch('cp/setVisible', false)
    const onFinish = async () => {
      try {
        const data = _.cloneDeep(toRaw(formState))
        if (await dispatch(formMode.value === FORM_MODE.CREATE ? 'cp/create' : 'cp/update', data)) {
          emit('saveSuccess')
        }
        // eslint-disable-next-line no-empty
      } catch {

      }
    }
    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      formModal,
      onFinish,
      onCancel,
      disabled,
      cpGroup,
      status: STATUS
    }
  },
})
</script>

<style scoped>

</style>
