<template>
  <a-form
      ref="formModal"
      :model="formState"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
      @finish="onFinish"
  >
    <a-form-item label="Username" name="username">
      <cds-input
          class="w-100 ant-input-sm" v-model:value="formState.username" :maxlength="50" show-count
          size="small"
          placeholder="Username"/>
    </a-form-item>
    <a-form-item label="Họ và tên" name="fullName">
      <cds-input
          class="w-100 ant-input-sm"
          v-model:value="formState.fullName"
          :maxlength="50"
          show-count
          size="small"
          placeholder="Họ và tên"/>
    </a-form-item>
    <a-form-item v-if="formMode === FORM_MODE.CREATE" label="Mật khẩu" name="password">
      <cds-input
          class="w-100 ant-input-sm" v-model:value="formState.password" :maxlength="50" show-count
          size="small"
          placeholder="Mật khẩu"/>
    </a-form-item>
    <a-form-item label="Mô tả" name="description">
      <cds-input
          class="w-100 ant-input-sm" v-model:value="formState.description" :maxlength="100" show-count
          size="small"
          placeholder="Mô tả"/>
    </a-form-item>
    <a-form-item label="Số điện thoại" name="phone">
      <cds-input
          class="w-100 ant-input-sm" v-model:value="formState.phone" :maxlength="12" show-count
          size="small"
          placeholder="Số điện thoại"/>
    </a-form-item>
    <a-form-item label="Giới tính" name="gender">
      <cds-select
          :options="genders"
          placeholder="Giới tính"
          v-model:value="formState.gender"/>
    </a-form-item>
    <a-form-item label="Vai trò" name="userRoles">
      <cds-select
          :options="roles"
          placeholder="Vai trò"
          multiple
          label-prop="roleName"
          value-prop="roleId"
          v-model:value="formState.userRoles"/>
    </a-form-item>
    <a-form-item label="Nhóm người dùng" name="cpId">
      <cds-select :options="cps"
                  v-model:value="formState.cpId"/>
    </a-form-item>
    <a-form-item label="Trạng thái" name="status">
      <cds-select :options="status" placeholder="Trạng thái" v-model:value="formState.status"/>
    </a-form-item>

    <hr class="footer">

    <div class="w-100 text-right">
      <cds-button class="mr-20" key="back" type="light" size="small" @click="onCancel">
        <cds-feather-icons class="mr-10" type="x"/>
        Hủy bỏ
      </cds-button>
      <cds-button
          key="submit"
          type="primary"
          size="small"
          type-html="submit"
      >
        <cds-feather-icons class="mr-10" type="save"/>
        Lưu
      </cds-button>
    </div>

  </a-form>
</template>

<script>
import {computed, defineComponent, reactive, ref, toRaw, watch} from 'vue'
import {useStore} from 'vuex'
import _ from 'lodash'
import {FORM_MODE, GENDERS, STATUS} from '@/util/common-constant'
import {maxlength, numberRule, required} from '@/util/validation'

export default defineComponent({
  emits: ['saveSuccess'],
  setup (props, {emit}) {
    const {state, dispatch} = useStore()
    const formState = reactive(state.user.user)
    const formMode = computed(() => state.user.formMode)
    const cps = computed(() => state.cp.listAll || [])

    const roles = computed(() => state.role.list)
    const rules = {
      username: [required('Username'), maxlength(50, 'Username')],
      fullName: [required('Họ và tên'), maxlength(50, 'Họ và tên')],
      description: [maxlength(100, 'Mô tả')],
      phone: [required('Số điện thoại'), numberRule('Số điện thoại'), maxlength(12, 'Số điện thoại')],
      deptId: [required('Phòng ban')],
      status: [required('Trạng thái')],
      gender: [required('Giới tính')],
      cpId: [required('Nhóm người dùng')],
      password: [required('Mật khẩu')],
    }
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    }
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    }
    const formModal = ref(null)
    const fieldNames = {label: 'name', key: 'value'}
    const onCancel = () => dispatch('user/setVisible', false)
    watch(() => formMode, (val) => {
      if (val === FORM_MODE.CREATE) {
        rules.password = [required('Mật khẩu')]
      } else {
        rules.password = []
      }
    })
    const onFinish = async () => {
      try {
        const data = _.cloneDeep(toRaw(formState))
        if (await dispatch(formMode.value === FORM_MODE.CREATE ? 'user/create' : 'user/update', data)) {
          emit('saveSuccess')
        }
        // eslint-disable-next-line no-empty
      } catch {

      }
    }
    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      cps,
      fieldNames,
      formModal,
      onFinish,
      onCancel,
      roles,
      status: STATUS,
      genders: GENDERS,
      formMode,
      FORM_MODE: FORM_MODE
    }
  },
})
</script>

<style scoped>

</style>
