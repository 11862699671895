<template>
  <a-form
    ref="formModal"
    :model="formState"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    :label-align="'left'"
    :rules="rules"
    :validate-trigger="['change', 'blur']"
    @finish="onFinish"
  >
    <a-form-item label="Tiêu đề" name="menuName">
      <cds-input
        class="w-100 ant-input-sm"
        :read-only="disabled"
        maxlength="50"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.menuName"
        placeholder="Tiêu đề"
      />
    </a-form-item>

    <a-form-item label="Menu Url" name="path">
      <cds-input
        class="w-100 ant-input-sm"
        :read-only="disabled"
        maxlength="255"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.path"
        placeholder="Menu Url"
      />
    </a-form-item>

    <a-form-item label="Component" name="component">
      <cds-input
        class="w-100 ant-input-sm"
        :read-only="disabled"
        maxlength="255"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.component"
        placeholder="Đường dẫn"
      />
    </a-form-item>

    <a-form-item label="Quyền" name="perms">
      <cds-input
        class="w-100 ant-input-sm"
        :read-only="disabled"
        maxlength="50"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.perms"
        placeholder="Quyền"
      />
    </a-form-item>

    <a-form-item label="Menu Icon" name="icon">
      <cds-input
        class="w-100 ant-input-sm"
        :read-only="disabled"
        iconName="'icon'"
        size="small"
        :icon="'settings'"
        :disabled="disabled"
        :show-count="false"
        placeholder="Menu Icon"
        v-model:value="formState.icon"
        @iconClick="iconClick"
      />
    </a-form-item>

    <a-form-item label="Thứ tự" name="orderNum">
      <cds-input-number
        class="w-100 ant-input-sm"
        :read-only="disabled"
        maxlength="15"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.orderNum"
        placeholder="Thứ tự"
      />
    </a-form-item>

    <a-form-item label="Menu cấp trên" name="parentId">
      <cds-tree-select
        :read-only="disabled"
        v-model:value="formState.parentId"
        :disabled="disabled"
        :treeData="menus"
        :field-names="fieldNames"
      />
    </a-form-item>

    <a-form-item label="Ngày tạo" v-if="disabled">
      {{ formatDateVN(formState.createdTime) }}
    </a-form-item>

    <a-form-item label="Ngày sửa" v-if="disabled">
      {{ formatDateVN(formState.modifyTime) }}
    </a-form-item>

    <hr class="footer" />

    <div class="w-100 text-right">
      <cds-button key="back" type="light" size="small" @click="onCancel">
        <cds-feather-icons class="mr-10" type="x" />
        {{ disabled ? 'Đóng' : 'Hủy bỏ' }}
      </cds-button>
      <cds-button
        class="ml-20"
        v-if="!disabled"
        key="submit"
        type="primary"
        size="small"
        type-html="submit"
      >
        <cds-feather-icons type="save" />
        Lưu
      </cds-button>
    </div>
  </a-form>

  <cds-modal
    :title="titleModal"
    :visible="visibleIcon"
    :width="'50vw'"
    :maskClosable="false"
    hide-footer
    @onCancel="visibleIcon = false"
  >
    <icon-menu ref="modal" @saveSuccess="saveSuccess"></icon-menu>
  </cds-modal>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { FORM_MODE } from '@/util/common-constant';
import { maxlength, numberRule, required } from '@/util/validation';
import IconMenu from './IconMenu';
import * as Icons from 'feather-icons';
import { mapFields } from 'vuex-map-fields';
import { formatDate } from '@/util/common-utils';

export default defineComponent({
  name: 'Menu',
  components: {
    IconMenu,
  },
  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const formState = reactive(state.menu.menu);
    const formMode = computed(() => state.menu.formMode);
    const formatDateVN = computed(() => formatDate);
    const disabled = formMode.value === FORM_MODE.VIEW;
    const rules = computed(() => {
      if (disabled) return {};
      return {
        menuName: [required('Tiêu đề'), maxlength(50, 'Tiêu đề')],
        path: [required('Menu Url'), maxlength(100, 'Menu Url')],
        component: [maxlength(100, 'Đường dẫn')],
        orderNum: [numberRule('Thứ tự')],
      };
    });
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    };
    const formModal = ref(null);
    const fieldNames = { label: 'menuName', value: 'menuId' };
    const setDisabled = (menu, disabled) => {
      menu.disabled = disabled;
      if (menu.children) {
        menu.children.forEach((m) => setDisabled(m));
      }
    };
    const icons = Icons.icons;
    const menus = computed(() => {
      const menu = state.menu.list;
      menu.forEach((m) => setDisabled(m, disabled));
      return menu;
    });
    const onCancel = () => dispatch('menu/setVisibleMenu', false);
    const onFinish = async () => {
      try {
        const data = _.cloneDeep(toRaw(formState));
        if (data.parentId && data.parentId.length > 0) {
          data.parentId = data.parentId.pop();
        }
        if (
          await dispatch(
            formMode.value === FORM_MODE.CREATE
              ? 'menu/createMenu'
              : 'menu/updateMenu',
            { ...data, type: 0 }
          )
        ) {
          emit('saveSuccess');
        }
        // eslint-disable-next-line no-empty
      } catch {}
    };
    return {
      onFinish,
      onCancel,
      formState,
      rules,
      labelCol,
      wrapperCol,
      menus,
      fieldNames,
      formModal,
      disabled,
      icons,
      dispatch,
      titleModal: 'Chọn icon',
      formatDateVN,
    };
  },
  computed: {
    ...mapFields('menu', ['visibleIcon']),
  },
  methods: {
    iconClick() {
      this.dispatch('menu/preChooseIcon');
    },
  },
});
</script>

<style scoped></style>
