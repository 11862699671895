import Table from '@/components/table/Table'
import {computed, onMounted, reactive, ref, toRaw} from 'vue'
import ConstantAPI from '@/config/ConstantAPI'
import ActionButton from '@/components/action-button'
import {ModalStyled} from '@/components/modals/styled'
import {useForm} from 'ant-design-vue/es/form'
import {useStore} from 'vuex'
import {formatDate, getText, greaterThanCurrent} from '@/util/common-utils'
import {maxlength} from '@/util/validation'
import _ from 'lodash'
import {mapFields} from 'vuex-map-fields'
import {STATUS_APPROVAL} from '@/util/common-constant'
import Tag from '@/components/tags/Tag';

const apiFetch = ConstantAPI.articles.SEARCH
export default {
    name: 'Cp',
    components: {
        'cds-table': Table,
        ActionButton,
        ModalStyled,
        Tag
    },
    setup() {
        const columns = ref([
            {
                title: 'Tiêu đề',
                dataIndex: 'title',
                key: 'title',
                resizable: true,
                width: 360,
            },
            {
                title: 'Người tạo',
                dataIndex: 'createdBy',
                key: 'createdBy',
                resizable: true,
                width: 260,
            },
            {
                title: 'Ngày tạo',
                key: 'createdTime',
                sortDirections: ['descend', 'ascend'],
                resizable: true,
                sortable: true,
                dataIndex: 'createdTime',
                customRender({record}) {
                    return formatDate(record.createdTime);
                },
                align: 'center',
                width: 260,
            },
            {
                title: 'Danh mục',
                dataIndex: 'categoryId',
                resizable: true,
                key: 'categoryId',
                width: 260,
                customRender({record}) {
                    const data = categories.value.find(e => e.value === record.categoryId);
                    if (!data) return ''
                    return data.label || '';
                },
            },
            {
                title: 'Ảnh',
                dataIndex: 'thumbPath',
                resizable: true,
                key: 'thumbPath',
                width: 260,
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                resizable: true,
                key: 'status',
                width: 260,
            },
            {
                key: 'action',
                width: 200,
            },
        ])
        const labelCol = {
            lg: 8,
            md: 8,
            xs: 24,
        }
        const wrapperCol = {
            lg: 16,
            md: 16,
            xs: 24,
        }
        const buttonsForm = [
            {type: 'RESET'},
            {type: 'SEARCH'},
            {type: 'APPROVAL', permission: `articles:approval`},
            {type: 'CANCEL_APPROVAL', permission: `articles:cancel-approval`},
        ];
        let buttonActions = [
            {type: 'ON-TOP', permission: `articles:on-top`},
            {type: 'APPROVAL', permission: `articles:approval`},
            {type: 'CANCEL_APPROVAL', permission: `articles:cancel-approval`}
        ];
        const table = ref(null)
        const {state, dispatch} = useStore()
        onMounted(() => {
        })
        const loading = computed(() => state.articles.loading)
        const categories = computed(() => state.category.listAll || []);
        const formState = reactive({
            name: '',
            status: null,
            categoryId: null,
            sort: 'id,desc'
        })
        const rules = ref({
            name: [maxlength(500, 'Tiêu đề')],
        })
        const {resetFields, validate, validateInfos} = useForm(formState, rules)
        let paramFetch = _.cloneDeep(toRaw(formState))
        const setParamFetch = () => {
            paramFetch.name = formState.name
            paramFetch.status = formState.status
            paramFetch.categoryId = formState.categoryId
        }
        const resetForm = () => {
            resetFields()
            formState.name = ''
            formState.status = null
            formState.categoryId = null
            setParamFetch()
            table.value.resetAndFetch()
        }
        const onSearch = () => {
            validate().then(
                () => {
                    setParamFetch()
                    table.value.fetchData().then()
                },
                () => {
                }
            )
        }
        const disabledDate = greaterThanCurrent
        const statusText = computed(() => (value) => {
            return getText(value, STATUS_APPROVAL)
        });
        return {
            columns,
            apiFetch,
            labelCol,
            wrapperCol,
            formState,
            resetFields,
            resetForm,
            onSearch,
            validate,
            validateInfos,
            rules,
            disabledDate,
            dispatch,
            table,
            paramFetch,
            loading,
            buttonsForm,
            buttonActions,
            categories,
            statusText,
            status: STATUS_APPROVAL
        }
    },
    computed: {
        ...mapFields('articles', ['visible']),
    },
    methods: {
        saveSuccess() {
            this.dispatch('articles/setVisible', false).then(() =>
                this.$refs.table.resetAndFetch(true)
            )
        },
        onApproval(row) {
            this.dispatch('articles/approval', [row]).then(() => {
                this.$refs.table.fetchData()
            })
        },
        onCancelApproval(row) {
            this.dispatch('articles/cancelApproval', [row]).then(() => {
                this.$refs.table.fetchData()
            })
        },
        onTop(row) {
            this.dispatch('articles/opTop', row).then()
        },
        async onDelete() {
            if (await this.dispatch('articles/delete', this.$refs.table.selectedRows)) {
                this.$refs.table.resetAndFetch(true)
            }
        },
        async onApprovals() {
            if (await this.dispatch('articles/approval', this.$refs.table.selectedRows)) {
                this.$refs.table.fetchData()
            }
        },
        async onCancelApprovals() {
            if (await this.dispatch('articles/cancelApproval', this.$refs.table.selectedRows)) {
                this.$refs.table.fetchData()
            }
        },
    },
}
