<template>
  <a-form
    ref="formModal"
    :model="formState"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    :label-align="'left'"
    :rules="rules"
    :validate-trigger="['change', 'blur']"
    @finish="onFinish"
  >
    <a-form-item label="Tiêu đề" name="menuName">
      <cds-input
        class="w-100 ant-input-sm"
        :read-only="disabled"
        maxlength="50"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.menuName"
        placeholder="Tiêu đề"
      />
    </a-form-item>

    <a-form-item label="Quyền" name="perms">
      <cds-input
        class="w-100 ant-input-sm"
        :read-only="disabled"
        maxlength="50"
        show-count
        size="small"
        :disabled="disabled"
        v-model:value="formState.perms"
        placeholder="Quyền"
      />
    </a-form-item>

    <a-form-item label="Menu cấp trên" name="parentId">
      <cds-tree-select
        :readonly="disabled"
        :tree-data="menus"
        :field-names="fieldNames"
        v-model:value="formState.parentId"
      />
    </a-form-item>

    <a-form-item label="Ngày tạo" v-if="disabled">
      {{ formatDateVN(formState.createdTime) }}
    </a-form-item>

    <a-form-item label="Ngày sửa" v-if="disabled">
      {{ formatDateVN(formState.modifyTime) }}
    </a-form-item>

    <hr class="footer" />

    <div class="w-100 text-right">
      <cds-button key="back" type="light" size="small" @click="onCancel">
        <cds-feather-icons class="mr-10" type="x" />
        {{ disabled ? 'Đóng' : 'Hủy bỏ' }}
      </cds-button>
      <cds-button
        class="ml-20"
        v-if="!disabled"
        key="submit"
        type="primary"
        size="small"
        type-html="submit"
      >
        <cds-feather-icons type="save" />
        Lưu
      </cds-button>
    </div>
  </a-form>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { FORM_MODE } from '@/util/common-constant';
import { maxlength, required } from '@/util/validation';
import * as Icons from 'feather-icons';
import { mapFields } from 'vuex-map-fields';
import { formatDate } from '@/util/common-utils';

export default defineComponent({
  name: 'Button',
  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const formState = reactive(state.menu.menu);
    const formatDateVN = computed(() => formatDate);
    const formMode = computed(() => state.menu.formMode);
    const disabled = formMode.value === FORM_MODE.VIEW;
    const rules = computed(() => {
      if (disabled) return {};
      return {
        menuName: [required('Tiêu đề'), maxlength(15, 'Tiêu đề')],
      };
    });
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    };
    const formModal = ref(null);
    const fieldNames = { label: 'menuName', value: 'menuId' };
    const setDisabled = (menu) => {
      menu.disabled = true;
      if (menu.children) {
        menu.children.forEach((m) => setDisabled(m));
      }
    };
    const icons = Icons.icons;
    const menus = computed(() => {
      const menu = state.menu.list;
      if (!disabled) return menu;
      menu.forEach((m) => setDisabled(m));
      return menu;
    });
    const onCancel = () => dispatch('menu/setVisibleButton', false);
    const onFinish = async () => {
      try {
        const data = _.cloneDeep(toRaw(formState));
        if (
          await dispatch(
            formMode.value === FORM_MODE.CREATE
              ? 'menu/createMenu'
              : 'menu/updateMenu',
            {
              ...data,
              type: 1,
            }
          )
        ) {
          emit('saveSuccess');
        }
        // eslint-disable-next-line no-empty
      } catch {}
    };
    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      menus,
      fieldNames,
      formModal,
      onFinish,
      onCancel,
      disabled,
      icons,
      dispatch,
      titleModal: 'Chọn icon',
      formatDateVN,
      formMode,
    };
  },
  computed: {
    ...mapFields('menu', ['visibleIcon']),
  },
  methods: {
    iconClick() {
      this.dispatch('menu/preChooseIcon');
    },
  },
});
</script>

<style scoped></style>
