import Table from '../../../../components/table/Table'
import {computed, onMounted, reactive, ref, toRaw} from 'vue'
import ConstantAPI from '@/config/ConstantAPI'
import ActionButton from '@/components/action-button'
import {ModalStyled} from '@/components/modals/styled'
import {useForm} from 'ant-design-vue/es/form'
import {useStore} from 'vuex'
import FormModal from './FormModal'
import {greaterThanCurrent} from '@/util/common-utils'
import {maxlength} from '@/util/validation'
import _ from 'lodash'
import {mapFields} from 'vuex-map-fields'
import {defaultButton} from '@/util/common-constant'

const apiFetch = ConstantAPI.cp.SEARCH
export default {
  name: 'Cp',
  components: {
    'cds-table': Table,
    ActionButton,
    ModalStyled,
    FormModal
  },
  setup () {
    const columns = ref([
      {
        title: 'Tên đối tác',
        dataIndex: 'name',
        key: 'name',
        resizable: true,
        width: 260,
      },
      {
        title: 'Tên nhóm',
        dataIndex: 'groupId',
        resizable: true,
        key: 'groupId',
        width: 260,
        customRender({ record }) {
          const data = cpGroup.value.find(e => e.value === record.groupId);
          if (!data) return ''
          return data.label || '';
        },
      },
      {
        title: 'Số điện thoại',
        dataIndex: 'tel',
        resizable: true,
        key: 'tel',
        width: 260,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        resizable: true,
        key: 'email',
        width: 260,
      },
      {
        key: 'action',
        width: 110,
      },
    ])
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    }
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    }
    const {buttonsForm, buttonActions} = defaultButton('cp')
    const table = ref(null)
    const {state, dispatch} = useStore()
    onMounted(() => dispatch('cpGroup/getAll'))
    const loading = computed(() => state.cp.loading)
    const cpGroup = computed(() => state.cpGroup.list || [])
    const formState = reactive({
      name: '',
      groupId: null,
      sort: 'id,desc'
    })
    const rules = ref({
      name: [maxlength(50, 'Tên đối tác')],
    })
    const {resetFields, validate, validateInfos} = useForm(formState, rules)
    let paramFetch = _.cloneDeep(toRaw(formState))
    const setParamFetch = () => {
      paramFetch.name = formState.name
      paramFetch.groupId = formState.groupId
    }
    const resetForm = () => {
      resetFields()
      formState.name = ''
      formState.groupId = null
      setParamFetch()
      table.value.resetAndFetch()
    }
    const onSearch = () => {
      validate().then(
        () => {
          setParamFetch()
          table.value.fetchData().then()
        },
        () => {
        }
      )
    }
    const disabledDate = greaterThanCurrent
    return {
      columns,
      apiFetch,
      labelCol,
      wrapperCol,
      formState,
      resetFields,
      resetForm,
      onSearch,
      validate,
      validateInfos,
      rules,
      disabledDate,
      dispatch,
      table,
      titleModal: 'Thêm mới Đối tác',
      paramFetch,
      loading,
      buttonsForm,
      buttonActions,
      cpGroup
    }
  },
  computed: {
    ...mapFields('cp', ['visible']),
  },
  methods: {
    preCreate () {
      this.dispatch('cp/preCreate').then(
        () => (this.titleModal = 'Thêm mới Đối tác')
      )
    },
    saveSuccess () {
      this.dispatch('cp/setVisible', false).then(() =>
        this.$refs.table.resetAndFetch(true)
      )
    },
    onEdit (row) {
      this.dispatch('cp/preUpdate', row).then()
      this.titleModal = 'Cập nhật Đối tác'
    },
    onView (row) {
      this.dispatch('cp/preView', row).then()
      this.titleModal = 'Xem chi tiết Đối tác'
    },
    async onDelete () {
      if (await this.dispatch('cp/delete', this.$refs.table.selectedRows)) {
        this.$refs.table.resetAndFetch(true)
      }
    },
  },
}
