import Table from '@/components/table/Table'
import {computed, onMounted, reactive, ref, toRaw} from 'vue'
import ConstantAPI from '@/config/ConstantAPI'
import ActionButton from '@/components/action-button'
import {ModalStyled} from '@/components/modals/styled'
import {useForm} from 'ant-design-vue/es/form'
import {useStore} from 'vuex'
import {greaterThanCurrent} from '@/util/common-utils'
import {maxlength, minlength, required} from '@/util/validation'
import _ from 'lodash'
import {mapFields} from 'vuex-map-fields'
import {defaultButton, MONEY_AMOUNT, TOP_UP_PROVIDER} from '@/util/common-constant'
import Tag from "@/components/tags/Tag";

const apiFetch = ConstantAPI.category.SEARCH
export default {
    name: 'TopUp',
    components: {
        'cds-table': Table,
        ActionButton,
        ModalStyled,
        Tag
    },
    setup() {
        const labelCol = {
            lg: 6,
            md: 6,
            xs: 24,
        }
        const wrapperCol = {
            lg: 18,
            md: 18,
            xs: 24,
        }
        onMounted(() => {
        })
        const {buttonsForm, buttonActions} = defaultButton('category')
        const table = ref(null)
        const {state, dispatch} = useStore()
        const loading = computed(() => state.topUp.loading)
        const amountChose = computed(() => state.topUp.amountChose);
        const formState = reactive({
            msisdn: '',
            provider: ''
        })
        const rules = ref({
            msisdn: [required('Số điện thoại'), minlength(10, 'Số điện thoại'), maxlength(10, 'Số điện thoại')],
            provider: [required('Nhà cung cấp')],
        })
        const {resetFields, validate, validateInfos} = useForm(formState, rules)
        let paramFetch = _.cloneDeep(toRaw(formState))
        const setParamFetch = () => {
            paramFetch.msisdn = formState.msisdn
            paramFetch.provider = formState.provider
        }
        const resetForm = () => {
            resetFields()
            formState.name = ''
            formState.provider = null
            setParamFetch()
        }
        const onFinish = () => {
            validate().then(
                async () => {
                    const data = _.cloneDeep(toRaw(formState))
                    if (state.topUp.amountChose) {
                        await dispatch('topUp/topUp', data)
                    }
                },
                () => {
                }
            )
        }
        const choseAmount = (val) => {
            dispatch('topUp/setAmount', val)
        }
        const disabledDate = greaterThanCurrent
        return {
            apiFetch,
            labelCol,
            wrapperCol,
            formState,
            resetFields,
            resetForm,
            onFinish,
            choseAmount,
            validate,
            validateInfos,
            rules,
            disabledDate,
            dispatch,
            table,
            amountChose,
            paramFetch,
            loading,
            buttonsForm,
            buttonActions,
            moneyAmount: MONEY_AMOUNT,
            topUpProviders: TOP_UP_PROVIDER,
        }
    },
    computed: {
        ...mapFields('category', ['visible']),
    },
    methods: {
        preCreate() {
            this.dispatch('category/preCreate').then(
                () => (this.titleModal = 'Thêm mới Chuyên mục')
            )
        },
        saveSuccess() {
            this.dispatch('category/setVisible', false).then(() =>
                this.$refs.table.resetAndFetch(true)
            )
        },
        onEdit(row) {
            this.dispatch('category/preUpdate', row).then()
            this.titleModal = 'Cập nhật Chuyên mục'
        },
        onView(row) {
            this.dispatch('category/preView', row).then()
            this.titleModal = 'Xem chi tiết Chuyên mục'
        },
        async onDelete() {
            if (await this.dispatch('category/delete', this.$refs.table.selectedRows)) {
                this.$refs.table.resetAndFetch(true)
            }
        },
    },
}
