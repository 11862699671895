import Table from '@/components/table/Table';
import { computed, onMounted, reactive, ref, toRaw } from 'vue';
import ConstantAPI from '@/config/ConstantAPI';
import ActionButton from '@/components/action-button';
import { ModalStyled } from '@/components/modals/styled';
import { useForm } from 'ant-design-vue/es/form';
import dayjs from 'dayjs';
import { useStore } from 'vuex';
import { formatDate } from '@/util/common-utils';
import { maxlength } from '@/util/validation';
import _ from 'lodash';
import { mapFields } from 'vuex-map-fields';
import FormMenuModal from './FormMenuModal';
import FormButtonModal from './FormButtonModal';
import { defaultButton } from '@/util/common-constant';
import Tag from '@/components/tags/Tag';

const apiFetch = ConstantAPI.menu.SEARCH;
export default {
  name: 'Menu',
  components: {
    'cds-table': Table,
    ActionButton,
    ModalStyled,
    FormMenuModal,
    FormButtonModal,
    Tag,
  },
  setup() {
    const columns = ref([
      {
        title: 'Tiêu đề',
        dataIndex: 'menuName',
        key: 'menuId',
        resizable: true,
        width: 260,
      },
      {
        title: 'Icon',
        dataIndex: 'icon',
        resizable: true,
        key: 'icon',
        width: 100,
        align: 'center',
        scopedSlots: { customRender: 'icon' },
      },
      {
        title: 'Kiểu',
        dataIndex: 'type',
        key: 'type',
        resizable: true,
        align: 'center',
        width: 150,
      },
      {
        title: 'Đường dẫn',
        dataIndex: 'path',
        key: 'path',
        resizable: true,
        width: 260,
      },
      {
        title: 'Thứ tự',
        dataIndex: 'orderNum',
        key: 'orderNum',
        resizable: true,
        width: 120,
      },
      {
        title: 'Ngày tạo',
        key: 'createTime',
        sortDirections: ['descend', 'ascend'],
        resizable: true,
        sortable: true,
        dataIndex: 'createTime',
        customRender({ record }) {
          return formatDate(record.createTime);
        },
        align: 'center',
        width: 260,
      },
      {
        title: 'Ngày sửa',
        key: 'modifyTime',
        resizable: true,
        sortable: true,
        dataIndex: 'modifyTime',
        customRender({ record }) {
          if (!record.modifyTime) return '';
          return formatDate(record.modifyTime);
        },
        align: 'center',
        width: 260,
      },
      {
        title: 'Tác vụ',
        key: 'action',
        width: 180,
      },
    ]);
    const labelCol = {
      lg: 4,
      md: 4,
      xs: 24,
    };
    const wrapperCol = {
      lg: 20,
      md: 20,
      xs: 24,
    };

    const formState = reactive({
      menuName: '',
      dateFrom: null,
      dateTo: null,
    });
    const rules = {
      menuName: [maxlength(15, 'Tiêu đề')],
    };
    const { resetFields, validate, validateInfos } = useForm(formState, rules);

    const resetForm = () => {
      resetFields();
      formState.createdTime = [];
      formState.menuName = '';
      paramFetch = toRaw(formState);
      table.value.resetAndFetch();
    };
    let paramFetch = _.cloneDeep(toRaw(formState));
    const table = ref(null);
    const { dispatch } = useStore();
    const typeMenu = computed(() => (value) => {
      if (value === 1) return { text: 'Nút', color: '#006899' };
      if (value === 0) return { text: 'Menu', color: '#e7699e' };
      return { text: 'Khác', color: '#7c7c7c' };
    });
    const onCancel = () => dispatch('menu/setVisibleIcon', false);
    const onSearch = () => {
      validate().then(
        () => {
          paramFetch.menuName = formState.menuName;
          if (formState.createdTime && formState.createdTime.length === 2) {
            paramFetch.dateFrom = formState.createdTime[0];
            paramFetch.dateTo = formState.createdTime[1];
          }
          table.value.resetAndFetch();
        },
        () => {}
      );
    };
    const disabledDate = (current) => current && current > dayjs().endOf('day');
    onMounted(() => dispatch('menu/getAll'));
    const { buttonActions } = defaultButton('menu');
    const buttonsForm = [
      { type: 'RESET' },
      { type: 'SEARCH' },
      { type: 'CREATE_BUTTON', permission: 'menu:create' },
      { type: 'CREATE_MENU', permission: 'menu:create' },
      { type: 'DELETE', permission: 'menu:delete' },
    ];
    return {
      resetForm,
      onSearch,
      onCancel,
      disabledDate,
      typeMenu,
      columns,
      apiFetch,
      labelCol,
      wrapperCol,
      formState,
      resetFields,
      validate,
      validateInfos,
      rules,
      dispatch,
      paramFetch,
      table,
      titleModal: '',
      buttonsForm,
      buttonActions,
    };
  },
  computed: {
    ...mapFields('menu', ['visibleMenu', 'visibleButton']),
  },
  methods: {
    async preCreateMenu() {
      this.titleModal = 'Thêm mới Menu';
      await this.dispatch('menu/preCreateMenu');
    },
    async preCreateButton() {
      this.titleModal = 'Thêm mới Nút';
      await this.dispatch('menu/preCreateButton');
    },
    async saveSuccess() {
      await this.dispatch('menu/setVisibleMenu', false);
      await this.dispatch('menu/setVisibleIcon', false);
      await this.dispatch('menu/setVisibleButton', false);
      this.dispatch('menu/getAll');
      this.$refs.table.fetchData();
    },
    async onEdit(row) {
      await this.dispatch('menu/preUpdate', row);
      this.titleModal = 'Cập nhật Menu';
    },
    async onView(row) {
      this.titleModal = 'Xem chi tiết Menu';
      await this.dispatch('menu/preView', row);
    },
    async onDelete() {
      if (await this.dispatch('menu/delete', this.$refs.table.selectedRows))
        this.$refs.table.fetchData();
    },
  },
};
