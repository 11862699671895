<template>
  <cds-main class="">
    <cds-cards title="Tìm kiếm">
      <a-form
          ref="formRef"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :labelAlign="'left'"
          :rules="rules"
          :validateTrigger="['change', 'blur']"
      >
        <a-row :gutter="30">
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Tiêu đề" name="menuName">
              <cds-input
                  class="ant-input-sm"
                  v-model:value="formState.menuName"
                  :maxlength="50"
                  show-count
                  size="small"
                  :disabled="false"
                  @press-enter="onSearch"
                  placeholder="Tiêu đề"
              />
            </a-form-item>
          </a-col>
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Ngày tạo">
              <cds-from-to-date
                  v-model:dateFrom="formState.dateFrom"
                  v-model:dateTo="formState.dateTo"
                  :disabled-date="disabledDate"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <cds-search-form-button
          :buttons="buttonsForm"
          @onClear="resetForm"
          @onSearch="onSearch"
          @onCreateMenu="preCreateMenu"
          @onCreateButton="preCreateButton"
          @onDelete="onDelete"
      />
    </cds-cards>

    <cds-cards title="Danh sách menu">
      <cds-table
          ref="table"
          has-checkbox
          :columns="columns"
          :api-fetch="apiFetch"
          :param-fetch="paramFetch"
          :key-field="'menuId'"
      >
        <template v-slot:[`icon`]="record">
          <cds-feather-icons :type="record.icon"/>
        </template>

        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'icon'">
            <span>
              <cds-feather-icons :type="record.icon" size="14"/>
            </span>
          </template>

          <template v-if="column.key === 'type'">
            <tag
                tag-type="colorful"
                :color="typeMenu(record.type).color"
                :text="typeMenu(record.type).text"
            >
              {{ typeMenu(record.type).text }}
            </tag>
          </template>

          <template v-if="column.key === 'action' && record">
            <action-button
                :buttons="buttonActions"
                @onEdit="onEdit(record)"
                @onView="onView(record)"
            />
          </template>
        </template>
      </cds-table>
    </cds-cards>
  </cds-main>

  <cds-modal
      :title="titleModal"
      :visible="visibleMenu"
      :maskClosable="false"
      hide-footer
      @onCancel="visibleMenu = false"
  >
    <form-menu-modal ref="modal" @saveSuccess="saveSuccess"/>
  </cds-modal>

  <cds-modal
      :title="titleModal"
      :visible="visibleButton"
      :maskClosable="false"
      hide-footer
      @onCancel="visibleButton = false"
  >
    <form-button-modal ref="modal" @saveSuccess="saveSuccess"/>
  </cds-modal>
</template>

<script src="./menu-controller.js"/>

<style scoped></style>
