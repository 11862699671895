import Table from '@/components/table/Table'
import {computed, onMounted, reactive, ref, toRaw} from 'vue'
import ConstantAPI from '@/config/ConstantAPI'
import ActionButton from '@/components/action-button'
import {ModalStyled} from '@/components/modals/styled'
import {useForm} from 'ant-design-vue/es/form'
import {useStore} from 'vuex'
import FormModal from './FormModal'
import {formatDate, getText, greaterThanCurrent} from '@/util/common-utils'
import {maxlength} from '@/util/validation'
import _ from 'lodash'
import {mapFields} from 'vuex-map-fields'
import {defaultButton, STATUS} from '@/util/common-constant'
import Tag from "@/components/tags/Tag";

const apiFetch = ConstantAPI.category.SEARCH
export default {
  name: 'Category',
  components: {
    'cds-table': Table,
    ActionButton,
    ModalStyled,
    FormModal,
    Tag
  },
  setup () {
    const columns = ref([
      {
        title: 'Tiêu đề',
        dataIndex: 'title',
        key: 'title',
        resizable: true,
        width: 260,
      },
      {
        title: 'Thư tự',
        dataIndex: 'order',
        key: 'order',
        resizable: true,
        align: 'right',
        width: 100,
      },
      {
        title: 'Ngày tạo',
        key: 'createTime',
        sortDirections: ['descend', 'ascend'],
        resizable: true,
        sortable: true,
        dataIndex: 'createTime',
        customRender({ record }) {
          return formatDate(record.createdTime);
        },
        align: 'center',
        width: 260,
      },
      {
        title: 'Trạng thái',
        dataIndex: 'isActive',
        resizable: true,
        key: 'isActive',
        width: 80,
        align: 'center',
      },
      {
        key: 'action',
        width: 110,
      },
    ])
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    }
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    }
    onMounted(() => {
      dispatch('category/getAll')
    })
    const {buttonsForm, buttonActions} = defaultButton('category')
    const table = ref(null)
    const {state, dispatch} = useStore()
    const loading = computed(() => state.category.loading)
    const status = computed(() => (value) => getText(value, STATUS));
    const formState = reactive({
      name: '',
      sort: 'id,desc'
    })
    const rules = ref({
      name: [maxlength(50, 'Tiêu đề')],
    })
    const {resetFields, validate, validateInfos} = useForm(formState, rules)
    let paramFetch = _.cloneDeep(toRaw(formState))
    const setParamFetch = () => {
      paramFetch.name = formState.name
    }
    const resetForm = () => {
      resetFields()
      formState.name = ''
      setParamFetch()
      table.value.resetAndFetch()
    }
    const onSearch = () => {
      validate().then(
        () => {
          setParamFetch()
          table.value.fetchData().then()
        },
        () => {
        }
      )
    }
    const disabledDate = greaterThanCurrent
    return {
      columns,
      apiFetch,
      labelCol,
      wrapperCol,
      formState,
      resetFields,
      resetForm,
      onSearch,
      validate,
      validateInfos,
      rules,
      disabledDate,
      dispatch,
      table,
      titleModal: 'Thêm mới Chuyên mục',
      paramFetch,
      loading,
      buttonsForm,
      buttonActions,
      status
    }
  },
  computed: {
    ...mapFields('category', ['visible']),
  },
  methods: {
    preCreate () {
      this.dispatch('category/preCreate').then(
        () => (this.titleModal = 'Thêm mới Chuyên mục')
      )
    },
    saveSuccess () {
      this.dispatch('category/setVisible', false).then(() =>
        this.$refs.table.resetAndFetch(true)
      )
    },
    onEdit (row) {
      this.dispatch('category/preUpdate', row).then()
      this.titleModal = 'Cập nhật Chuyên mục'
    },
    onView (row) {
      this.dispatch('category/preView', row).then()
      this.titleModal = 'Xem chi tiết Chuyên mục'
    },
    async onDelete () {
      if (await this.dispatch('category/delete', this.$refs.table.selectedRows)) {
        this.$refs.table.resetAndFetch(true)
      }
    },
  },
}
