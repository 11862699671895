<template>
  <div>
    <a-row>
      <a-col :xxl="8" :xl="8" :lg="8" :md="8" :xs="24">
        <figure class="text-center">
          <a-avatar shape="square" :size="164"
                    :src="formState.avatar">
          </a-avatar>
        </figure>
        <figcaption>
          <div class="info text-center">
            <cds-heading as="h4">{{ formState.fullName }}</cds-heading>
            <p>{{ getCpName(formState.cpId) }}</p>
          </div>
        </figcaption>
      </a-col>
      <a-col :xxl="16" :xl="16" :lg="16" :md="16" :xs="24">
        <a-form
            ref="formModal"
            :model="formState"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label-align="left"
        >
          <a-form-item label="Username">
            {{ formState.username }}
          </a-form-item>
          <a-form-item label="Họ và tên">
            {{ formState.fullName }}
          </a-form-item>
          <a-form-item label="Giới tính">
            {{ genderText(formState.gender) }}
          </a-form-item>
          <a-form-item label="Ngày sinh">
            {{ formatDateVN(formState.birthday, 'DD/MM/YYYY') }}
          </a-form-item>
          <a-form-item label="Địa chỉ">
            {{ formState.address }}
          </a-form-item>
          <a-form-item label="Số điện thoại">
            {{ formState.phone }}
          </a-form-item>
          <a-form-item label="Vai trò">
            {{ roleText(formState.userRoles) }}
          </a-form-item>
          <a-form-item label="Trạng thái">
            {{ statusText(formState.status) }}
          </a-form-item>
          <a-form-item label="Ngày tạo">
            {{ formatDateVN(formState.createdTime) }}
          </a-form-item>
          <a-form-item label="Ngày sửa">
            {{ formatDateVN(formState.modifyTime) }}
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { formatDate, getText } from "@/util/common-utils";
import { GENDERS, STATUS } from "@/util/common-constant";

export default defineComponent({
  name: "UserDetail",
  setup() {
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    };
    const { state } = useStore();
    const formState = ref(state.user.user);
    const cps = computed(() => state.cp.listAll);
    const roles = computed(() => state.role.list);
    const statusText = computed(() => value => getText(value, STATUS));
    const genderText = computed(() => value => getText(value, GENDERS));
    const getCpName = computed(() => cpId => {
      const dept = (cps.value || []).filter(d => d.value === cpId);
      if (dept && dept.length > 0) {
        return dept[0].label;
      }
      return '';
    });
    const formatDateVN = computed(() => (date, format = 'DD/MM/YYYY HH:mm:ss') => formatDate(date, format));
    const roleText = computed(() => (roleIds) => {
      if (!roleIds || !roleIds.length) return '';
      return roles.value.filter(r => roleIds.includes(r.roleId)).map(r => r.roleName).join(', ');
    })
    return {
      formState,
      getCpName,
      labelCol,
      wrapperCol,
      formatDateVN,
      statusText,
      genderText,
      roleText
    }
  }
});
</script>

<style scoped>

</style>
