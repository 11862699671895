<template>
  <div class="w-100 mb-10">
    <cds-feather-icons :type="icon.name" v-for="icon in icons" :key="icon.name"
                       @click="chooseIcon(icon.name)" size="14"
                       :class="((chose !== icon.name ? 'icon ' : 'active-icon ') + icon.name)"/>
  </div>

  <hr class="footer">

  <div class="w-100 text-right">
    <cds-button class="mr-20" key="back" type="light" size="small" @click="onCancel">
      <cds-feather-icons class="mr-10" type="x"/>
      Hủy bỏ
    </cds-button>
    <cds-button
        key="submit"
        type="primary"
        size="small"
        type-html="submit"
        @click="saveIcon"
    >
      <cds-feather-icons class="mr-10" type="check" />
      Chọn
    </cds-button>
  </div>

</template>

<script>


import { defineComponent } from 'vue';
import { useStore } from "vuex";
import * as Icons from 'feather-icons';

export default defineComponent({
  setup() {
    const { state, dispatch } = useStore();
    const icons = Icons.icons;
    const onCancel = () => dispatch('menu/setVisibleIcon', false);
    let chose = null;
    return {
      onCancel,
      chose,
      icons,
      state,
      dispatch
    };
  },
  methods: {
    chooseIcon(data) {
      this.chose = data;
      this.$forceUpdate();
    },
    saveIcon() {
      if (this.chose) {
        this.state.menu.menu.icon = this.chose;
        this.onCancel();
      }
    }
  },
});
</script>

<style scoped>
.icon {
  padding: 0.2rem;
  margin: 1rem;
  cursor: pointer;
}

.icon:hover {
  color: #4278db;
}

.active-icon {
  padding: 0.2rem;
  margin: 1rem;
  color: #4278db;
}

</style>
